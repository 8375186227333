<template>

	<div>
		<div class="block-header">
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<div>
								<el-button v-if="operation!=='查看'" type="primary" size="medium" style="float: right;" @click="submit">
									{{operation}}
								</el-button>
								<el-page-header @back="go(`/project-merchants-list/${page}`)" :content="'接待客商--'+operation">
								</el-page-header>
							</div>

							<el-form ref="form" label-width="160px" size="small">

								<el-form-item label="填报单位">
									<el-row>
										<el-col :span="10">
											<el-tag type="success">{{form.填报单位}}</el-tag>
										</el-col>
									</el-row>
								</el-form-item>

								<el-row>
									<el-col :span="12">
										<el-form-item label="来大冶企业名称" required>
											<el-input v-model="form.企业名称"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="接待时间" required>
											<el-date-picker v-model="form.接待时间" align="left" type="date"
												placeholder="选择日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
												value-format="yyyy-MM-dd">
											</el-date-picker>
										</el-form-item>
									</el-col>

									<el-col :span="10" :offset="2">
										<el-form-item label="客商人数" required>
											<el-input-number v-model="form.客商人数" controls-position="right" :min="1">
											</el-input-number>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="洽谈内容" required>
											<el-input v-model="form.洽谈内容" type="textarea"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="备注">
											<el-input v-model="form.备注" type="textarea"></el-input>
										</el-form-item>
									</el-col>

								</el-row>


								<el-form-item label="接待方案" required>
									<el-tag effect="plain" type="danger" style="margin-bottom: 5px;">
										上传图片或word或pdf,单个文件大于20MB的将不会被上传成功
									</el-tag>
									<multiple-file-upload v-model="form.接待方案"></multiple-file-upload>
								</el-form-item>

								<el-form-item label="接待场景图片" required>
									<el-tag effect="plain" type="danger" style="margin-bottom: 5px;">上传接待场景图片
									</el-tag>
									<multiple-file-upload v-model="form.接待场景图片" :file-format="['.png','.jpg']">
									</multiple-file-upload>
								</el-form-item>
								
								<el-form-item v-if="operation!=='审批' && operation!=='填报'" label="审批信息">
									<el-form-item>
										{{form.审批状态+" "+	(form.审批意见?form.审批意见:'')}}
									</el-form-item>
								</el-form-item>


								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批">
											<el-radio-group v-model="form.审批状态">
												<el-radio-button label="待审批"></el-radio-button>
												<el-radio-button label="审批通过"></el-radio-button>
												<el-radio-button label="审批未通过"></el-radio-button>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批意见">
											<el-input v-model="form.审批意见" :autosize="{ minRows: 2, maxRows: 4}"
												type="textarea"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

							</el-form>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import projectMerchantsController from "../controller/projectMerchantsController.js"
	import MultipleFileUpload from '../components/MultipleFileUpload.vue';
	export default {
		components: {
			MultipleFileUpload
		},
		data() {
			return {
				operation: '',
				id: '',
        page:1,
				isApproval: false,
				form: {
					接待方案: [],
					接待场景图片: [],
				},
				user: {},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
			}
		},
		created() {
			this.id = this.getParam('id');
			this.isApproval = this.getParam('approval') === 'approval'
			this.user = JSON.parse(sessionStorage.getItem('user'))
      this.page = this.getParam('page');

			if (this.isApproval) {
				this.operation = '审批'
				this.initForm();
			} else if (this.id) {
				this.operation = '修改'
				this.initForm();
			} else {
				this.operation = '填报'
				this.form.填报单位 = this.user.dept_name
			}

		},
		methods: {
			initForm() {
				projectMerchantsController.get({
					unique: this.id
				}).then(res => {
					this.form = res.data;
					if(this.form.modified.status<0){
						this.operation = '查看';
					}
				})
			},
			submit() {
				if (!this.validate())
					return;

				let obj = this.deepClone(this.form)

				if (this.isApproval) {
					projectMerchantsController.approval(obj).then(res => {
            this.go(`/project-merchants-list/${this.page}`)
					})
				} else if (obj.id) {
					projectMerchantsController.put(obj).then(res => {
            this.go(`/project-merchants-list/${this.page}`)
					})
				} else {
					obj.接待方案 = JSON.stringify(obj.接待方案);
					obj.接待场景图片 = JSON.stringify(obj.接待场景图片);
					projectMerchantsController.post(obj).then(res => {
						this.go(`/project-merchants-list/${this.page}`)
					})
				}

			},
			validate() {

				if (!this.form.企业名称) {
					this.$message.error('请填写来大冶企业名称')
					return false;
				}

				if (!this.form.接待时间) {
					this.$message.error('请填写接待时间')
					return false;
				}

				if (!this.form.客商人数) {
					this.$message.error('请填写客商人数')
					return false;
				}

				if (!this.form.洽谈内容) {
					this.$message.error('请填写洽谈内容')
					return false;
				}

				if (this.form.接待方案.length < 1) {
					this.$message.error('请填写接待方案')
					return false;
				}

				if (this.form.接待场景图片.length < 1) {
					this.$message.error('请上传接待场景图片')
					return false;
				}

				return true;

			}
		}

	}
</script>

<style scoped>
	.el-page-header {
		margin-bottom: 40px;
	}

	.el-date-picker {
		cursor: pointer;
	}
</style>
